import React from 'react'
import { Accordion , Card, Button } from 'react-bootstrap'

const CollapsibleItem = (props) => {
    return (<Card>
        <Card.Header className="card-header p-0">   
            <Accordion.Toggle as={Button} variant="link" eventKey={props.eventKey}>
                <h2 className="mb-0">
                    <span className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {props.title}
                    </span>
                </h2> 
            </Accordion.Toggle>
                    
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
            <Card.Body className="card-body p-0">
                {props.children}
            </Card.Body>
        </Accordion.Collapse>
    </Card>)
}


function CollapsiblePanel (props) {
    //console.log(props, "props")

    return(
        <div className="buyer-collapse">
            <Accordion defaultActiveKey={1}>
                <CollapsibleItem title={'My Solicitor'} eventKey={1}>
                    <ul>
                        {/* eslint-disable-next-line no-mixed-operators */}
                        <li>{props.solicitor && props.solicitor.user_information_fullname || ''}</li>
                        <li>{props.solicitor && props.solicitor.user_information_email_address}</li>
                        <li>{props.solicitor && props.solicitor.user_information_mobile_number}</li>
                        <button className="btn" onClick={() => props.openPopupForMessage(props.solicitor.user_information_email_address)}>message me</button>
                    </ul> 
                </CollapsibleItem>
                <CollapsibleItem title={'Building & Pest Inspector'} eventKey={2}>
                    <ul>
                        {/* eslint-disable-next-line no-mixed-operators */}
                        <li>{props.inspector && props.inspector.user_information_fullname || ''}</li>
                        <li>{props.inspector && props.inspector.user_information_email_address}</li>
                        <li>{props.inspector && props.inspector.user_information_mobile_number}</li>
                        <button className="btn" onClick={() => props.openPopupForMessage(props.inspector.user_information_email_address)}>message me</button>
                    </ul>
                </CollapsibleItem>
                <CollapsibleItem title={'My Agent'} eventKey={3}>
                    {/* eslint-disable-next-line no-mixed-operators */}
                    <ul>
                        <li>{(props.agent && props.agent.user_information_fullname) || ''}</li>
                        <li>{props.agent && props.agent.user_information_email_address}</li>
                        <li>{props.agent && props.agent.user_information_mobile_number}</li>
                        <button className="btn" onClick={() => props.openPopupForMessage(props.agent.user_information_email_address)}>message me</button>
                    </ul>
                    {/* <p> Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aut</p> */}
                </CollapsibleItem>
                <CollapsibleItem title={'My Financial Broker'} eventKey={4}>
                    <ul>
                        {/* eslint-disable-next-line no-mixed-operators */}
                        <li>{props.broker && props.broker.user_information_fullname || ''}</li>
                        <li>{props.broker && props.broker.user_information_email_address}</li>
                        <li>{props.broker && props.broker.user_information_mobile_number}</li>
                        <button className="btn" onClick={() => props.openPopupForMessage(props.broker.user_information_email_address)}>message me</button>
                    </ul>
                </CollapsibleItem>
            </Accordion>
        </div>
    )

}

export default CollapsiblePanel