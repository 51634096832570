import React, { useEffect } from 'react';
import Header from './components/Common/Header';
import Footer from './components/Common/Footer';
import Routes from './Routes'
import Notification from './components/Common/Notification'
import { getPricingPackages } from './actions/common';
import { connect, useSelector, useDispatch } from 'react-redux';

function App(props) {

  const packages = useSelector(state => state.common.packages)
  const dispatch = useDispatch()

  useEffect(() => {
    // props.getPackages()
    dispatch(getPricingPackages())
  }, [dispatch])

  return (
    <div>
      <Header packages={packages}
      // getPackages={props.getPackages}
      />
      <div className="route-box">
        <Routes packages={props.packages} />
      </div>
      <Footer />
      <Notification />
    </div>
  )
}

// const mapStateToProps = state => ({
//   packages : state.common.packages 
// })

// const mapDispatchToProps = dispatch => ({
//   getPackages: () => dispatch(getPricingPackages())
// })


// export default connect(mapStateToProps, mapDispatchToProps)(App);
export default App;