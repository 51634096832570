import React from 'react'


function Banner(props){
    return (
        <section className="banner">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Buying a right property to <span>Live or Invest?</span></h1>
                        <h2>Let's help you search and secure <br/> a deal with just few clicks.</h2>
                        <p>Helping people make smart decisions!</p>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="btn-lg-pink" onClick={props.open}>Get Started<span onClick={props.open}><img src="images/get-started.png" alt=""/></span></a>
                    </div>
                </div>
            </div>
        </section>
        )
 }
export default Banner