import React, { useState, useEffect } from 'react'
import { registerAPI, loginAPI, socialLogin } from '../../Apis/auth'
import Loader from 'react-loader-spinner'
import Modal from 'react-responsive-modal'
import { validateLogin } from '../../validations/login'
import { validate } from '../../validations/signup'
import ForgotPassword from '../Auth/ForgotPassword'
import { toast } from 'react-toastify'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
//       userId: null,
//       password: null,
//       emailaddress:"",
//       fullname: "",
//       responseError: false,
//       loggedIn: false,
//       password:"",
//       c_password:"",
//       mobilenumber:"",
//       buttonLabel:'Register',
//       loginPage:[],
//       uploadScreen:[]


function LoginModal(props)  {
  const [ show, setShowRegister ] = useState(false)
  const [ loginInfo, setLoginInfo ] = useState({})
  const [ registerInfo, setRegisterInfo ] = useState({}) 
  const [ apiError, setApiError] = useState('')
  const [ apiSuccess, setApiSuccess] = useState('')
  const [ buttonLoading, setButtonLoading] = useState(false)
  const [ loginErrors, setLoginErrors] = useState({})
  const [ signinErrors, setSigninErrors] = useState({})
  const [ showForgot, setShowForgot] = useState(false)
  const [ viewSignup ,setShowSignup ] = useState(false)

  useEffect(() => {
    setApiError('')
	setLoginErrors({})
	setSigninErrors({})
	setApiSuccess('')
	setButtonLoading(false)
	setLoginInfo({})
  }, [])
 
  const register = () => {
	let result = validate(registerInfo)
	setSigninErrors(result)
	if(!(Object.keys(result).length > 0)) {
		setButtonLoading(true)
		registerAPI(registerInfo)
			.then(res => {
				if(res && res.length > 0){
					toast.success('Signed up successfully!')
					sessionStorage.setItem('user', JSON.stringify(res[0]))
					setTimeout(() => {
						props.onClose()
						setButtonLoading(false)
					}, 2000)
				}
				else if(res && res.status !== 200){
					toast.error(res.message)
					setButtonLoading(false)
				}
			})
	}
    
  }


  const checkRole = (role) => {
	switch (role) {
		case 'SOLICITOR':
			window.location = '/property_status_view'
			break;
		case 'INSPECTOR':
			window.location = '/property_status_view'
			break;
		case 'BROKER':
			window.location = '/property_status_view'
			break;
		case 'AGENT':
			window.location = '/agent_dashboard'
			break;
		case 'CUSTOMER':	
			window.location = '/buyer_property_list'
			break;
		case 'SUPER_ADMIN':
			window.location = '/search'
			break;
		default:
			break;
	}
  }

  
  const login = (e) => {
	e.preventDefault();
	let result = validateLogin(loginInfo)
	setLoginErrors(result)
	if(!(Object.keys(result).length > 0)) {
    	setButtonLoading(true)
		loginAPI(loginInfo)
			.then(res => {
				if(res && res.length > 0){
					toast.success('Logged in successfully!')
					sessionStorage.setItem('user', JSON.stringify(res[0]))

					if((res[0].role === 'CUSTOMER') && (res[0].plan_uuid === null)){
						//setPricingDisplay(true)
						window.location = "/user_profile"
					} else {
						checkRole(res[0].role)
					}
					setTimeout(() => {
						//window.location.reload();
						//props.onClose()
						setButtonLoading(false)
					}, 2000)
				}
				else if(res && res.response && res.response.status !== 200){
					toast.error(res.response.data.response)
					setButtonLoading(false)
				}
			})
  		} 
	}

	const setForgot = () => {
		//props.onClose()
		setShowForgot(true)
	}

	
	// eslint-disable-next-line no-unused-vars
	const toggleSignupView = (role) => {
		if( role === 'CUSTOMER' ){
			setShowSignup(true)
		}
		else {
			setShowSignup(false)
		}
		setLoginInfo({ ...loginInfo, 'user_role_mapping_role' : role })
	}

	const responseGoogle = (response) => {
		//console.log(response, "google")
		let info = response.profileObj || {}
		let data = {
			"email":info.email,
			"account_type":"GOOGLE", 
			"user_role_mapping_role":"CUSTOMER"
		}
		if(info && (info.email !== '')){
			socialLogin({...data})
				.then((res,err) => {
					console.log(res, "google login api response")
					if(res && res.length > 0) {
						setTimeout(() => {
							toast.success('Logged in successfully!')
							sessionStorage.setItem('user', JSON.stringify(res[0]))
							window.location = '/buyer_property_list'
						}, 1000)
					}
					else if(res.response.data.error){
						toast.error(res.response.data.error)
					}
					else {
						toast.error(err)
					}
					
				})
		}
		else {
			toast.error('Unable to collect email.')
		}
	}
	
	const responseFacebook = (response) => {
		//console.log(response, "facebook")
		if(response && (response.email !== '')){
			let data = {
				"email":response.email,
				"account_type":"FACEBOOK", 
				"user_role_mapping_role":"CUSTOMER"
			}
			socialLogin({...data})
				.then(res => {
					console.log(res, res.error, res.status, "facebook login api response")
					if(res && res.length > 0) {
						setTimeout(() => {
							toast.success('Logged in successfully!')
							sessionStorage.setItem('user', JSON.stringify(res[0]))
							window.location = '/buyer_property_list'
						}, 1000)	
					}
					else {
						toast.error('Issue occured while logging in.')
					}
				})
		}
		else {
			toast.error('Unable to collect email information from Facebook.')
		}

	}

	//console.log(props, "props")
	return (
    <Modal open={props.open} onClose={props.onClose} >
      {/* <ul className="nav nav-pills" id="pills-tab" role="tablist">
        <li className="nav-item" onClick={() => setShowRegister(false)}>
          <a className="nav-link active" data-toggle="pill" href="#pills-home" role="tab">Log In</a>
        </li>
        <li className="nav-item" onClick={() => setShowRegister(true)}>
          <a className="nav-link" data-toggle="pill" href="#pills-profile" role="tab">Register</a>
        </li>
      </ul>   */}
      {(apiError !== '') ?  <p className="alert">{apiError}</p> : null}
	  {(apiSuccess !== '') ?  <p className="success">{apiSuccess}</p> : null}
			<div className="tab-content" id="pills-tabContent">
				{show ? 
					<div className="tab-pane fade show active" role="tabpanel">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">
								Please fill in this form to create an account.</h5>
						</div>
						<div className="modal-body">
							<form>
								<div className="form-group">
									<input type="text" className="form-control" id="recipient-name"  placeholder="Full Name" onChange={(e) => setRegisterInfo({...registerInfo, 'fullName': e.target.value })}/>
								</div>
								<div className="form-group">
									<input type="text" className="form-control" id="recipient-name"  placeholder="Email" onChange={(e) => setRegisterInfo({...registerInfo, 'email': e.target.value })}/>
									{signinErrors['email'] && signinErrors['email'].length > 0 ? <p className="alert">{signinErrors['email'][0]}</p> : null}
								</div>
								<div className="form-group relative">
									<input type="number" className="form-control" id="exampleInputPassword1" placeholder="Phone Number" onChange={(e) => setRegisterInfo({...registerInfo, 'phone_number': e.target.value })}/>
									{signinErrors['phone_number'] && signinErrors['phone_number'].length > 0 ? <p className="alert">{signinErrors['phone_number'][0]}</p> : null}
								</div>
								<div className="form-group relative">
									<input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"  onChange={(e) => setRegisterInfo({...registerInfo, 'password': e.target.value })}/>
									{signinErrors['password'] && signinErrors['password'].length > 0 ? <p className="alert">{signinErrors['password'][0]}</p> : null}
								</div>
								{/* <div className="form-group relative">
									<input type="password" className="form-control" id="exampleInputPassword1" placeholder="Repeat Password" onChange={(e) => setRegisterInfo({...registerInfo, 'c_password': e.target.value })}/>
								</div> */}
								<div>
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
									<p>By creating an account you agree to our <a href="#">Terms & Privacy</a>.</p>
								</div>
							</form>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn pink_btn width_100" onClick={register}>
								{buttonLoading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30}/>: 'Register'}</button>
						</div>  
						
					</div> : 
					<div className="tab-pane fade show active empadding" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Log in your Account</h5>
						</div>
						<div className="modal-body">
							<form onSubmit={login}>
								<div className="form-group">
									<input type="text" className="form-control" id="recipient-name" 
										// id="exampleInputemail1"  
										placeholder="Email"  
										onChange={(event) => setLoginInfo({...loginInfo, 'email': event.target.value })}/>
									{loginErrors['email'] && loginErrors['email'].length > 0 ? 
										<p className="alert">{loginErrors['email'][0]}</p> 
										: null
									}
								</div>
								<div className="form-group relative">
									<input type="password" className="form-control" id="exampleInputPassword1" name="password" onChange={(event) => setLoginInfo({...loginInfo, 'password': event.target.value })} placeholder="Password"/>
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
									<a className="forgot" onClick={setForgot}>Forgot?</a>
									{loginErrors['password'] && loginErrors['password'].length > 0 ? 
										<p className="alert">{loginErrors['password'][0]}</p> 
										: null
									}
								</div>
								{/* <div className="form-group relative">
								<select className="form-control" id="state-filter" onChange={e => toggleSignupView(e.target.value)}>
									<option value="">Select Role</option>
									{userTypes.map(user => <option value={user.value}>{user.label}</option>)}		
								</select> 
									{loginErrors['user_role_mapping_role'] && loginErrors['user_role_mapping_role'].length > 0 ? <p className="alert">{loginErrors['user_role_mapping_role'][0]}</p> : null}
								</div> */}
								<div className="modal-footer">   
									<button  type="submit" className="btn pink_btn width_100">
									{buttonLoading ? <Loader type="ThreeDots" color="#FFFFFF" height={20} width={30}/>: 'Login'}</button>  
								</div>
							</form>
						</div>

						<div className="login-connect">
							<div className="seprator"> 
								<div ><hr/></div>
								OR
								<div ><hr/></div>
							</div>
							{/* <h5 className="modal-title" id="exampleModalLongTitle justify-content-center">Or Log in With : </h5> */}
							<div className="social_icon d-flex justify-content-center">
								{/* <a href="#"><img src="/images/social/faceboook.png" alt=""/></a> */}
								<FacebookLogin
									appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
									autoLoad={false}
									fields="name,email,picture"
									onClick={() => { console.log('clicked') }}
									callback={responseFacebook}
									render={renderProps => (
										<button className="social-btn" onClick={renderProps.onClick}>
											<i className="ri-facebook-fill fb"></i>
										</button>
									  )}
									
								/>
								{/* <a href="#"><img src="/images/twitter02.png" alt=""/></a> */}
								{/* <a href="#"><img src="/images/googleplus.png" alt=""/></a> */}
								<GoogleLogin
									autoLoad={false}
									clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
									buttonText="Login"
									isSignedIn={false}
									onSuccess={responseGoogle}
									onFailure={() => {}}
									cookiePolicy={'single_host_origin'}
									render={renderProps => (
										<button className="social-btn" onClick={renderProps.onClick} disabled={renderProps.disabled}>
											<i className="ri-google-fill go"></i>
										</button>
									  )}
								/>
								{/* <a href="#"><img src="/images/rss.png" alt=""/></a> */}
							</div>
						</div> 
						<div className="signin">
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<p>Don't have an account? <a href="#" onClick={props.openRegister}>Sign Up</a>.</p>
						</div>
						<ForgotPassword show={showForgot} onClose={ () => setShowForgot(false)}/>
					</div>}
				</div>
    </Modal>
  )
}


export default LoginModal