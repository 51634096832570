import React from 'react'


const images = [
    {
        'image': "images/card01.png"
    }, 
    {
        'image': "images/card02.png"
    }, 
    {
        'image': "images/card03.png"
    }, 
    {
        'image': "images/card04.png"
    }, 
    {
        'image': "images/card05.png"
    }, 
    {
        'image': "images/card06.png"
    }, 
    {
        'image': "images/card07.png"
    }, 
    {
        'image': "images/card08.png"
    }
]


function Cards(props){
    return (
        <section className="what_we_do">
            <div className="container-fluid">
                <h2 className="text-center">What We Do</h2>
                <div className="row">
                    {images.map((info, index)=> <CardBlock {...info} key={index} redirect={props.redirect}/>)}
                </div>
            </div>
        </section>
    )
 }
export default Cards


const CardBlock = (props) => {
    return (
        <div className="col-md-3">
            <div className="card" onClick={props.redirect}>
                <img className="card-img-top" src={props.image} alt="" />
                <div className="card-body">
                    <h5 className="card-title">Selective Listing</h5>
                    <p className="card-text">Based on strict criteria</p>
                </div>
            </div>
        </div>
    )
}