import React from 'react'

function Logo() {
    return (
        <div>
            <a className="navbar-brand" href="/">
                <img src="/images/logo.png" alt="" />
            </a>
        </div>
    )
}

export default Logo
