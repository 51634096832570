export const property = {
    "property_near_school":"NO",
	"property_info_location": "",
	"property_lat": "",
	"property_long": "",
	"property_info_bedroom": '',
	"property_info_bathroom": '',
	"property_info_year_build": "",
	"property_info_land_size": "",
	"property_info_median_price": "",
	"property_info_list_price": "",
	"equity_amount": "",
	"property_info_rental_yield": "0",
	"property_info_long_term_growth": "",
	"property_info_cash_income": "",
	"property_info_growth": "",
	"property_info_rental": "",
	"property_info_ownership_type": "",
	"property_info_type": "",
	"state": "",
	"postcode": "",
	"suburb": "",
	"property_info_area_flood": "NO",
	"property_info_council_issues": "NO",
	"property_info_good_neighbourhood": "NO",
	"property_info_garage": "",
	"property_info_status": "ACTIVE",
	"property_near_transport": "NO",
	"property_near_beach": "NO",
	"property_near_city": "NO",
	"property_near_granny": "NO",
	"is_expert_picks": "NO",
	"is_most_view": "NO",
	"is_new_in_market": "NO",
	"is_promoted": "NO",
	"is_rare_find": "NO",
	"is_spacius_home": "NO",
	"is_stress_free": "NO",
	"property_near_subdivide": "NO",
	"property_info_desc": "",
	"expert_description": "",
	"facilities": [],
	"highlight": [],
	"highlights": "",
	"property_images_name": [],
    "property_images": []
}