import React, { useState, useEffect } from 'react'
import { logOut } from '../../utils/api'
import Modal from '../Modal/LoginModal'
import SignupModal from '../Modal/SignupPlanModal'
import LogedInDropdown from './LogedInDropdown'
import Logo from './Logo'

function Header(props) {
  const [showLogin, openLoginModal] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [showSignup, openSignupModal] = useState(false)
  const [user, setUser] = useState({})

  useEffect(() => {
    let info = JSON.parse(sessionStorage.getItem('user'))
    if (info && info.user_information_uuid) {
      setLoggedIn(true)
      setUser(info)
    }
  }, [showLogin, showSignup])


  const clicklogOut = () => {
    setLoggedIn(false)
    logOut()
    logOut()
    window.location = "/"
  }


  const openRegister = () => {
    openSignupModal(true)
    openLoginModal(false)
  }

  // const goToFavourites = () => {
  //   if (user.role === 'AGENT') {
  //     window.location = '/agent_dashboard'
  //   }
  // }

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <Logo />
      <button
        className="navbar-toggler"
        type="button" data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
          <li className="nav-item ">
            <a className="nav-link" href="/about">About us <span className="sr-only">(current)</span></a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/How_it_works">How It Works</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/contact">Contact Us</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/pricing" >Pricing</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/faq">FAQs</a>
          </li>
          {/* <PricingModal packages={props.packages} hideRegister={false}/> */}
          <li className="nav-item">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {!loggedIn && <a className="nav-link loginBtn" onClick={() => openLoginModal(true)}>LOGIN</a>}
          </li>

          {loggedIn ?
            <LogedInDropdown user={user} clicklogOut={clicklogOut} />
          : null}
        </ul>
        <Modal 
          // getPackages={props.getPackages}
          open={showLogin}
          onClose={
            () => openLoginModal(false)}
          openRegister={openRegister}
          packages={props.packages} />
        <SignupModal 
          // getPackages={props.getPackages}
          show={showSignup} onClose={() => openSignupModal(false)} packages={props.packages} />
      </div>
    </nav>
  )
}

export default Header