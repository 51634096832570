import React, { useState } from 'react'

function LogedInDropdown(props) {

    const [show, toggle] = useState(false)
    const user = props.user
    const role = user.role

    return (
        <div>
            <li className="nav-item">
                <div className="dropdown nav-dropdown">
                    <button onClick={() => toggle(!show)}
                        className="btn btn-primary dropdown-toggle nav-dropdown-btn"
                        type="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded={show}>
                        {
                            (user && user.user_information_uuid) ?
                                (user.user_information_fullname !== '') ?
                                    user.user_information_fullname
                                    : (user.role !== '') ?
                                        'LoggedIn ' + user.role
                                        : 'LoggedIn User'
                                : 'Logged In User'
                        }
                        <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu">
                        <li><a href="/user_profile">Profile</a></li>
                        {role !== 'AGENT' && <li><a href="/property_status_view">Property Status</a></li>}
                        {((role === 'AGENT') || (role === 'CUSTOMER')) && <li><a href="/search"> All Properties</a></li>}
                        {(role === 'CUSTOMER') && <li><a href="/buyer_property_list">Property/Offer List</a></li>}
                        {(role === 'AGENT') && <li><a href="/agent_dashboard" onClick={() => { window.location = '/agent_dashboard' }}>Dashboard</a></li>}
                        {(role === 'AGENT') && <li><a href="/manage_property">Manage Property</a></li>}
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <li><a onClick={props.clicklogOut}>Logout</a></li>
                    </ul>
                </div>
            </li>
        </div>
    )
}

export default LogedInDropdown
