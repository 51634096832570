import React from 'react'
import moment from 'moment'

function BuyerStatus (props) {

    const { offerList = [] } = props
    return (
        <div className="buyer-status">
            <ul>
                {offerList && offerList.length > 0 ? 
                    offerList.map((offer,index) => 
                    <li key={index} >
                        <div><h5>{moment(offer.offer_updated_at).format('MMM DD, YYYY')}</h5></div>
                        <div><span className={(offer.marked_as ===  "Pending") ? "pending" : "complete"}>{offer.marked_as}</span></div>
                        <div><h5>{offer.status}</h5></div>
                    </li>) :
                    <ul>
                        <li>
                            <div><h5>May 01,2020</h5></div>
                            <div><span className="due">due</span></div>
                            <div><h5>Cooling off Period</h5></div>
                        </li>
                        <li>
                            <div><h5>May 01,2020</h5></div>
                            <div><span className="pending">pending</span></div>
                            <div><h5>Pay inked Deposit</h5></div>
                        </li>
                        <li>
                            <div><h5>May 01,2020</h5></div>
                            <div><span className="assigned">assigned</span></div>
                            <div><h5>Pay inked Deposit</h5></div>
                        </li>
                    </ul>
                } 
            </ul>      
        </div>
    )
}

export default BuyerStatus