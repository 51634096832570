import * as types from '../action_types/common'

let intialState = {
  highlights: [], 
  suburbs: [], 
  postal_codes: [], 
  packages:[]
}

export default (state = intialState, action) => {
    switch (action.type) {
     case types.GET_HIGHLIGHTS:
      return { ...state, highlights: action.payload }
     case types.GET_PRICING_PACKAGES:
       return { ...state, packages: action.payload }
     default:
      return state
    }
   }