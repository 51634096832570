import React from 'react'


function DefaultLoading (){
    return(
        <div>
            <div className="product-grid">
                <div className="container-fluid">
                      <h3>Redirecting ....</h3>   
                </div>
            </div>
        </div>)
}

export default DefaultLoading