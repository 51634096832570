import React from 'react'
import Footer from '../Common/Footer'
import Header from '../Common/Header'


const HowitWorks = () => {
    
return (
  <>
  <section className="management spacing">
        <div className="container">
           <div className="head">
              <h2>Smarter, Faster, more reliable property <br />
                  management. Here’s how we’re different</h2>
           </div>

           <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                 <div className="box">
                    <div className="inner">
                     <img src="images/info.png" alt=""/>
                     <h5>Info when you need it</h5>
                     <p>Real time & Responsive</p>
                    </div>
                 </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                 <div className="box">
                    <div className="inner">
                     <img src="images/group.png" alt=""/>
                     <h5>Team of experts</h5>
                     <p>Our specialised service</p>
                    </div>
                 </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                 <div className="box">
                    <div className="inner">
                     <img src="images/bill.png" alt=""/>
                     <h5>Our $100/month fee</h5>
                     <p>Flat and fair</p>
                    </div>
                 </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
                 <div className="box">
                    <div className="inner">
                     <img src="images/quality.png" alt=""/>
                     <h5>Quality maintenance</h5>
                     <p>Streamlined & easy</p>
                    </div>
                 </div>
              </div>
              
              <div className="col-lg-3 col-md-3 col-sm-3 col-12">
               <div className="box">
                  <div className="inner">
                     <img src="images/localization.png" alt=""/>
                  <h5>Local teams & offices</h5>
                  <p>Sydney, Melbourne,
                     Brisbane & Gold Coast</p>
                  </div>
               </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
               <div className="box">
                  <div className="inner">
                     <img src="images/unpaid.png" alt=""/>
                  <h5>The Best tenants</h5>
                  <p>High quality candidates</p>
                  </div>
               </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
               <div className="box">
                  <div className="inner">
                     <img src="images/search-icn.png" alt=""/>
                  <h5>Regular inspections</h5>
                  <p>Photos, checklist &
                     more</p>
                  </div>
               </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12">
               <div className="box">
                  <div className="inner">
                     <img src="images/shield.png" alt=""/>
                  <h5>Landlord insuarance</h5>
                  <p>Free for your first year</p>
                  </div>
               </div>
            </div>
           </div>
        </div>
     </section>
     
     <section className="technology location team-member spacing">
      <div className="container">
       <div className="row">
          <div className="col-lg-6 col-xs-12 d-flex order-2 order-md-1">
             <div className="content">
                <span>On-demand information</span>
                <h2>Intelligent technology</h2>
                 <p>Our technology is there for you 24/7, so you can access your information in real-time. That's fast. When you need to talk to someone our dedicated team are available over the phone, on email, live chat and through the owner portal.</p>
                 <ul>
                    <label>features</label>
                  <li><img src="images/success.png" alt="success"/>24/7 access to owners portal</li>
                  <li><img src="images/success.png" alt="success"/>On demand access to your leases, statements, maintenance requests & more</li>
                  <li><img src="images/success.png" alt="success"/>Tenant app for fast communication & streamlined requests</li>
                  <li><img src="images/success.png" alt="success"/>Fast & responsive communication</li>
                 </ul>
                 <button type="button" className="btn">Demo owners portal</button>
             </div>
          </div>
          
          <div className="col-lg-6 col-xs-12 order-1">
            <div className="content_img">
               <img src="images/technology.png" className="w-100 img-responsive"/>
            </div>
          </div>
       </div>
      </div>
   </section>

   
  
   <section className="dedicate-team technology team-member location spacing">
      <div className="container">
       <div className="row">
          <div className="col-lg-6 col-xs-12 d-flex order-2 order-md-1">
             <div className="content">
                <span>We’re here for you</span>
                <h2>A dedicated team</h2>
                 <p>All we do is property management. This means our time & resources are solely focused on giving our landlords the best experience.
                  Instead of assigning you a single agent, you get a team of local specialists looking after you, your tenants & your property</p>
                 <ul>
                    <label>features</label>
                  <li><img src="images/success2.png" alt="success"/>Round-the-clock service, better responsiveness & reliability. No sick days, holidays or late morning starts here.</li>
                  <li><img src="images/success2.png" alt="success"/>Thanks to our intelligent tech, every team member can stay on top of your property & understand the situation instantly.</li>
                  <li><img src="images/success2.png" alt="success"/>Issues and requests are handled faster and landlords and tenants both feel looked after.</li>
                 </ul>
                 <button type="button" className="btn">Take The Quiz</button>
                 <button type="button" className="btn meet-team">meet the team</button>
             </div>
          </div>
          
          <div className="col-lg-6 col-xs-12 order-1">
            <div className="content_img">
               <img src="images/dedicate-team.jpg" className="w-100 img-responsive"/>
            </div>
          </div>
       </div>
      </div>
   </section>
   <section className="dedicate-team technology team-member location">
      <div className="container">
       <div className="row">
          <div className="col-lg-4 mx-auto col-xs-12 d-flex order-1">             
            <div className="content_img">
               <img src="images/promotion.png" className="w-100 img-responsive"/>
            </div>
          </div>
          
          <div className="col-lg-6 col-xs-12 order-2 order-md-1">
            <div className="content">
               <span><img src="images/pricing.png" className="img-responsive" width="30" style={{ marginRight: "10" }}/>Pricing</span>
               <h2>$100 per month</h2>
                <p>No hidden fees (including GST). We believe owners deserve fair
                  pricing so we’ve introduced a flat monthly fee for everyone</p>
                <button type="button" className="btn">Compare Our Pricing</button>
                <button type="button" className="btn meet-team">Saving Calculator</button>
            </div>
          </div>
       </div>
      </div>
   </section>
   <section className="technology location team-member spacing">
      <div className="container">
       <div className="row">
          <div className="col-lg-6 col-xs-12 d-flex order-2 order-md-1">
             <div className="content">
                <span>Your peace of mind</span>
                <h2>Free landlord insurance &
                  rental guarantee</h2>
                 <p>We've got your back. Your first year of comprehensive landlord insurance is free and we guarantee rent payments when a tenant is more than 5 days late. This covers you for rent defaults, tenant damage, eviction and more.</p>
                 <button type="button" className="btn">Free Landlord Insurance</button>
                 <button type="button" className="btn meet-team">Rent Guarantee</button>
             </div>
          </div>
          
          <div className="col-lg-6 col-xs-12 order-1">
            <div className="content_img">
               <img src="images/landload-insurance.jpg" className="w-100 img-responsive"/>
            </div>
          </div>
       </div>
      </div>
   </section>
   <section className="dedicate-team technology team-member location spacing">
      <div className="container">
       <div className="row">
          <div className="col-lg-6 col-xs-12 d-flex order-2 order-md-1">
             <div className="content">
                <span>Leasing done right</span>
                <h2>Attracting the best tenants</h2>
                 <p>We take a two-step approach to leasing: finding you the best, high-quality, long-term tenants AND working hard to keep them there.
                  For finding new tenants, we charge a flat leasing fee of $500 plus $500 marketing fee in NSW & Vic or $400 marketing fee in QLD.
                  Already have tenants? You don't lose your tenants when you switch to :Different & you don't pay extra to keep them.</p>
                 <ul>
                    <label>features</label>
                  <li><img src="images/success2.png" alt="success"/>Professional photography</li>
                  <li><img src="images/success2.png" alt="success"/>Listing on Domain & Realestate.com</li>
                  <li><img src="images/success2.png" alt="success"/>Promoted listings to boost views</li>
                  <li><img src="images/success2.png" alt="success"/>Open homes mid-week & weekends</li>
                  <li><img src="images/success2.png" alt="success"/>Pre-approval processes</li>
                 </ul>
                 <button type="button" className="btn">Learn More</button>
             </div>
          </div>
          
          <div className="col-lg-6 col-xs-12 order-1">
            <div className="content_img">
               <img src="images/dedicate-team.jpg" className="w-100 img-responsive"/>
            </div>
          </div>
       </div>
      </div>
   </section>
   <section className="dedicate-team technology team-member location spacing-bottom">
      <div className="container">
       <div className="row">
          <div className="col-lg-6 col-xs-12 d-flex order-1">             
            <div className="content_img">
               <img src="images/regular-inspections.jpg" className="w-100 img-responsive"/>
            </div>
          </div>
          
          <div className="col-lg-6 col-xs-12 order-2 order-md-1">
            <div className="content">
               <span>Photos, checklists & more</span>
               <h2>Regular inspections</h2>
                <p>Automatically scheduled with advance notice sent to tenants. You get full visibility with inspection reports available in your owners portal immediately.</p>
            </div>
          </div>
       </div>
      </div>
   </section>
   <section className="technology location team-member spacing">
      <div className="container">
       <div className="row">
          <div className="col-lg-6 col-xs-12 d-flex order-2 order-md-1">
             <div className="content">
                <span>Streamlined & easy</span>
                <h2>Efficient maintenance</h2>
                 <p>Tenants report issues through our mobile app with photos and videos. We review and use our judgement to determine what's appropriate to best look after your home. Once approved, our system matches the best tradesperson for the job and everyone gets progress updates through their portal for complete visibility.</p>
                 <button type="button" className="btn">Watch a Demo</button>
                 <button type="button" className="btn meet-team">Try Out Our Owners Portal</button>
             </div>
          </div>
          
          <div className="col-lg-6 col-xs-12 order-1">
            <div className="content_img">
               <img src="images/efficient-maintenance.png" className="w-100 img-responsive"/>
            </div>
          </div>
       </div>
      </div>
   </section>
   


   <section className="check-service spacing">
      <div className="container">
         <div className="head">
            <h2>Check if we service your area</h2>
         </div>
         <div className="col-lg-6 offset-lg-3">
            <div className="location_search">
               <input type="text" placeholder="Search by suburb or postcode"/>
               <button type="button" className="btn">check location</button>
            </div>
         </div>
      </div>
   </section>
   </>
 )   
}



export default HowitWorks